<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row align-items-center justify-content-between">
          <div class="col-md-3" v-if="googleAccess.email">
            <b>{{ googleAccess.email }}</b>
          </div>
          <div class="col-md-3" v-if="googleAccess.email">
            <b>{{ googleAccess.schedule_count }}</b> Upcoming Event
          </div>
          <div class="col-md-3" v-if="googleAccess.updated_at">
            <b>Last Update : </b>{{ googleAccess.updated_at | date_time_format }}
          </div>
          <div v-if="!googleAccess.email" class="col-md-9">
            <div v-if="$route.query.status == 'failed'" v-else class="alert alert-danger p-1" role="alert">
              <b>Failed</b> to take access. Please try again!
            </div>
            <div v-if="!googleAccess.email" v-else class="alert alert-warning p-1" role="alert">
              <b>Notable:</b> Please give your <b>google account</b> access for calender integration. So that, we can
                              book a <b>schedule</b> with you for interview.
            </div>
          </div>
          <div class="col-md-3 text-right d-flex justify-content-end">
            <b-button :disabled="googleAccess.event_count > 0" @click="redirectToGoogleAccess"
                      class="text-right d-flex"
                      style="padding-top: 7px;padding-bottom: 7px;">
              <img src="/google-logo.png" alt="Google Logo" style="height: 20px; width: 20px; margin-right: 5px">
              <span style="padding-top: 3px">Access</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BRow,
} from "bootstrap-vue";
import {AlertError, HasError} from "vform/src/components/bootstrap5";

export default {
  data() {
    return {
      googleAccess: {},
    };
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
    AlertError,
  },
  mounted() {
    this.checkStatus();
    this.getAccountInfo();
  },
  methods: {
    redirectToGoogleAccess() {
      axios
          .get("/candidate/google/access")
          .then((res) => {
            window.location.href = res.data.auth_url;
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },
    getAccountInfo() {
      axios
          .get("/candidate/google-account-info")
          .then((res) => {
            this.googleAccess = res.data.accountInfo;
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },
    checkStatus() {
      const status = this.$route.query.status;

      if (status === "success") {
        this.s('Successfully access given.');
      } else if (status === "failed") {
        this.e("Failed to give access. Please try again!");
      }
    },
  }
};
</script>

<style scoped>
</style>
