<template>
  <div>
    <GoogleAccess></GoogleAccess>
    <PreferenceSchedule></PreferenceSchedule>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BRow,
} from "bootstrap-vue";
import { AlertError, HasError } from "vform/src/components/bootstrap5";
import GoogleAccess from "@/views/ScheduleSetting/partials/GoogleAccess.vue";
import PreferenceSchedule from "@/views/ScheduleSetting/partials/PreferenceSchedule.vue";

export default {
  data() {
    return {
      form: new Form({
        //
      }),
    };
  },
  components: {
    PreferenceSchedule,
    GoogleAccess,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
    AlertError,
  },
  mounted() {
    //
  },
  methods: {
    //
  }
};
</script>

<style scoped>
</style>
