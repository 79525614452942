<template>
  <div>
    <form
        @submit.prevent="update(preferredSchedule.id)"
    >
      <div class="card card-body">
        <div class="row text-right justify-content-end">
          <div class="col-md-12">
            <b-button class="btn btn-info" @click="edit()">
              <feather-icon icon="EditIcon"/>
            </b-button>
          </div>
        </div>
        <hr>
        <div class="row align-items-center justify-content-end">
          <div class="col-md-4">
            <b>Minimun Schedule Notice</b>
          </div>
          <div class="col-md-4 d-flex" >
            <b v-if="!form.id">{{ preferredSchedule.delay_time }} hours</b>
            <div class="form-group m-0" v-if="form.id">
              <InputNumber
                  v-model="form.delay_time"
                  placeholder="Enter your preferred minimum schedule notice"
                  style="width: 100%"
                  :max="72"
                  :min="1"
              />
              <has-error :form="form" field="delay_time"></has-error>
            </div>
            <div class="ml-1" style="margin-top: 5px" v-if="form.id">(Hours)</div>
          </div>
          <div class="col-md-4 text-right">
          </div>
        </div>
        <hr>

        <hr>
        <div class="row align-items-center justify-content-end">
          <div class="col-md-4">
            <b>Meeting Availability</b>
            <span  v-if="form.id" class="text-muted">&nbsp;(In hour, 24 format)</span>
          </div>
          <div class="col-md-4" v-if="preferredSchedule.start_time && preferredSchedule.end_time && !form.id">
            <b>{{ preferredSchedule.start_time > 12 ? preferredSchedule.start_time - 12 : preferredSchedule.start_time}}
              {{ preferredSchedule.start_time < 12 ? " : 00 AM" : " : 00 PM" }} -
              {{ preferredSchedule.end_time ? preferredSchedule.end_time - 12 : preferredSchedule.end_time }}
              {{ preferredSchedule.end_time < 12 ? " : 00 AM" : " : 00 PM" }}
              ({{ preferredSchedule.end_time - preferredSchedule.start_time }} hrs)</b>
          </div>
          <div class="col-md-8" v-if="form.id">
            <div class="d-flex">
              <div class="form-group m-0" v-if="form.id">
                <InputNumber
                    v-model="form.start_time"
                    placeholder="Enter your available start time"
                    style="width: 30%"
                    :max="form.end_time - 1"
                    :min="1"
                /> : 00
                <has-error :form="form" field="start_time"></has-error>
              </div>
              <div class="form-group m-0" v-if="form.id">
                <InputNumber
                    v-model="form.end_time"
                    placeholder="Enter your available end time"
                    style="width: 30%"
                    :max="23"
                    :min="form.start_time + 1"
                /> : 00
                <has-error :form="form" field="end_time"></has-error>
              </div>
            </div>
          </div>
          <div class="col-md-4 text-right" v-if="!form.id">
          </div>
        </div>
        <hr>
        <div class="row text-right justify-content-end" v-if="form.id">
          <div class="col-md-12">
            <b-button class="btn btn-danger mr-1" @click="clear">Cancel</b-button>
            <b-button class="btn btn-success" type="submit">Update</b-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BRow,
} from "bootstrap-vue";
import {AlertError, HasError} from "vform/src/components/bootstrap5";

export default {
  data() {
    return {
      form: new Form({
        id: null,
        start_time: null,
        end_time: null,
        delay_time: null,
      }),
      preferredSchedule: {}
    };
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
    AlertError,
  },
  mounted() {
    this.showPreferredSchedule();
  },
  methods: {
    showPreferredSchedule() {
      axios
          .get("/candidate/preferred/schedule")
          .then((res) => {
            this.preferredSchedule = res.data.preferredSchedule
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },
    edit() {
      axios
          .get("/candidate/preferred/schedule")
          .then((res) => {
            this.form.id = res.data.preferredSchedule.id;
            this.form.start_time = res.data.preferredSchedule.start_time;
            this.form.end_time = res.data.preferredSchedule.end_time;
            this.form.delay_time = res.data.preferredSchedule.delay_time;
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },
    update(id) {
      this.form
          .put("/candidate/preferred/schedule/" + id)
          .then((res) => {
            this.s(res.data.message);
            this.showPreferredSchedule();
            this.clear();
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },
    clear() {
      this.form.id = null;
      this.form.start_time = null;
      this.form.end_time = null;
      this.form.delay_time = null;
    }
  }
};
</script>

<style scoped>
</style>
